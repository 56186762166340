import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout';
// import { getObject } from '../../services/storage'
// import {S3ENV} from '../../components/envar'
import { Container, Row, Col, Collapse, ListGroup, ListGroupItem } from 'reactstrap'
import { faqDetails } from '../../data/faqdata';
import Breadcrumb from '../../components/breadcrumb'
import ReactHtmlParser from 'react-html-parser'
import ReactGA from 'react-ga'

export default class faq extends Component {

    componentDidMount(){
        ReactGA.initialize(`$(process.env.GA_KEY)`);
        ReactGA.pageview('support/faq');
    }

    render() {
        return (
            <FaqLayout location={this.props.location}>
                <Row>
                    <Col md={6} sm={12}>
                        <h2>Acccount Management</h2>
                        <FaqContent data={faqDetails.data.account_management}/>
                    </Col>
                    <Col md={6} sm={12}>
                        <h2>API Library and Testing</h2>
                        <FaqContent data={faqDetails.data.api_testing}/>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <div className="wp-block-button is-style-squared"><a className="wp-block-button__link has-text-color has-very-light-gray-color has-background has-vivid-cyan-blue-background-color" href="https://www.nets.com.sg/faqs/business/enets-credit/" target="_blank" rel="noopener noreferrer">eNETS</a></div>
                        <div className="wp-block-button is-style-squared"><a className="wp-block-button__link has-background has-vivid-cyan-blue-background-color" href="https://www.nets.com.sg/faqs/business/nets-qr/" target="_blank" rel="noopener noreferrer">NETS QR</a></div>
                    </Col>
                </Row>
            </FaqLayout>

        )
    }
}

export class FaqContent extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.state = { 
            collapse: false,
            itemKey : null,
            key: null
        }

    }

    toggle(e) {

        var element = e.currentTarget.id

        this.setState({ 
            collapse: !this.state.collapse,
            key : parseInt(element)
        })

    }

    render() {
        return(
            <ListGroup style={{marginLeft:0}}>
                {this.props.data.map((value, index) => {
                    return(
                        <div key={index}>
                            <ListGroupItem onClick={this.toggle} id={index} tag="a" href="#" style={{backgroundColor: `#f7f7f9`}}>
                                <b>{value.title}</b>
                            </ListGroupItem>
                            <Collapse isOpen={this.state.key === index ? true : false} >
                                <Col md={12} style={{border:`1px solid #ccc`, borderTop: `0`, borderRadius:` 0 0 3px 3px`, padding:`1rem 1.75rem `}}>
                                    {ReactHtmlParser(value.content)}
                                </Col>
                            </Collapse>
                        </div>
                    )}
                )}

            </ListGroup>
        )
    }
}



export const FaqLayout = props => {
    const data = {
        pathname : "/support/FAQ"
    }
    return (
        <Layout>
        <div className="wp-block-cover has-background-dim-80 has-background-dim has-left-content aligncenter"
            style={{
                backgroundImage:"url(/assets/images/header-bg2.png)",
                backgroundColor:"#2b2b2b",
                marginBottom: "0",
                minHeight: "100px",
                maxHeight: "100px"
        }}>
            <p className="wp-block-cover-text">
                FAQ
            </p>
        </div>
        <Breadcrumb loc={data}/>
        <Container
        style={{
            padding: '2rem 2rem 1rem',
            minHeight: '75vh'
        }}
        >
            {props.children}
        </Container>
    </Layout>
    )
}
