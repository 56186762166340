export const faqDetails = {
    data : {
        account_management :
        [
            {
                title : `Why should I register for an account before I can access the API?`,
                content : `Registering an account with us enables you to have access to our API library, view API keys and book eNETS certification date. <br/><br/>
                It also facilitates forum discussion and enquiry form generation.`,
            },
            {
                title : `What are the steps to register for a developer account?`,
                content : `<ol><li>Visit&nbsp;<a href="https://developer.nets.com.sg/">https://developer.nets.com.sg</a></li><li>Click on "Sign Up"</li><li>Complete the account creation fields</li><li>Activate your account</li></ol>`,
            },
            {
                title : `I didn't receive activation email. What should I do?`,
                content : `<p>Request for activation email to be resent:</p><ol><li>Click on "Sign up/ Sign in" on the top bar</li><li>Click on "Forgot password/Resend Activation"</li><li>Enter your registered email address</li><li>An activation email will be sent to your registered email address</li></ol><p><em>Note: The system generated activation email may have been filtered into your spam/junk box or blocked by your email client/firewall. Please add “NETS Developer Portal&nbsp;<a href="mailto:noreply@nets.com.sg">noreply@nets.com.sg</a>” into your safe list.</em></p><p><strong>Wrong email address entered during registration:</strong><br>You can write in to us at&nbsp;<a href="mailto:developersupport@nets.com.sg">developersupport@nets.com.sg</a>&nbsp;to request for a change of email address. Alternatively, you can create a new account using the correct email address.</p>`
            },
            {
                title : `What if I have forgotten my Password?`,
                content : `<ol><li>Click on forget password link</li><li>Enter your registered email address</li><li>An reset password hyperlink will be sent to your email</li></ol><p>If you did not receive the email for reset password, write in to us at&nbsp;<a href="mailto:developersupport@nets.com.sg">developersupport@nets.com.sg</a>&nbsp;for us to assist you.&nbsp;</p><p><em>Note: The system generated activation email may have been filtered into your spam/junk box or blocked by your email client/firewall. Please add “NETS Developer Portal&nbsp;<a href="mailto:noreply@nets.com.sg">noreply@nets.com.sg</a>” into your safe list.</em></p>`
            },
            {
                title : `My account is blocked`,
                content : `<p>You have exceeded the number of tries to sign in with the correct credentials.</p><p>A notification will be sent to your registered email address. Please check your email for more guidance on how to lift the block.</p><p>If you did not receive the notification email,</p><ol><li>Click on "Sign up/ Sign in" on the top bar</li><li>Click on "Forgot password/Resend Activation"</li><li>Enter your registered email address</li><li>A notification email will be resent to your registered email address</li></ol><p><em>Note: The system generated activation email may have been filtered into your spam/junk box or blocked by your email client/firewall. Please add “NETS Developer Portal&nbsp;<a href="mailto:noreply@nets.com.sg">noreply@nets.com.sg</a>” into your safe list.</em></p>`
            },
            {
                title : `How can I update my particulars?`,
                content : `<ol><li>Sign in to your account</li><li>Under “My Account”, select “My profile”</li></ol><p>Note: Email address is not available for user's update. If you would like to change it, drop us an email at&nbsp;<a href="mailto:developersupport@nets.com.sg">developersupport@nets.com.sg</a>.</p>`
            },
            {
                title : `How do I delete my account?`,
                content : `<p>Please note that once your account is deleted, all existing sandbox and production applications that are created under your account will also be deleted and no longer be available for use.</p> <p>If you wish to proceed, you can send your request to developersupport@nets.com.sg.</p>`
            },
            {
                title : `How do I change my email address?`,
                content : `<p>To change your email address, please first sign up for a new account with the new email address.</p><p>You can then proceed to send your email change request to developersupport@nets.com.sg indicating your current account and the new account to change to. Our support team will assist with the porting over of created applications and their keys. </p><p>You will receive an email notification once this has been completed.</p>`
            }
        ],
        api_testing : [
            {
                title : `Where can I find API library?`,
                content : `<p>API library is only accessible after you sign in to your activated account. After signing in, “API Library” will be displayed at the top of the page.</p>`
            },
            {
                title : `How can I request for service ID for testing?`,
                content : `<p>Download <a href="https://www.nets.com.sg/support/">Application for NETS Services</a>&nbsp;form and submit to&nbsp;<a href="mailto:sales_enets@nets.com.sg">sales_enets@nets.com.sg</a>`
            },
            {
                title : `How can I request for API Key?`,
                content : `<p>Login to your account and look under "My Applications". Once you have created an application, the API key and secret will be viewable.</p>`
            },
            {
                title : `Where can I view Secret?`,
                content : `<ol><li>Login to your developer account</li><li>Go to My Account/ Application</li><li>Select the application which you would like to view</li><li>Click on “View Details” to view API Key and Secret</li></ol>`
            },
            {
                title : `I want to allow my other technology team to view my keys. How do I do it?`,
                content : `<ol><li>Login to your developer account</li><li>Go to My Account/ Application</li><li>Select the application that you would like to delegate users to view your keys</li><li>Click on + Add New User</li><li>Enter email address on who the user would like to assign view to</li><li>Your delegated users will be able to view your assigned keys under their account “My Account/ Application”</li></ol><p><i>Please note that by allowing this, you will be delegating access to your delegated users to view your keys. However, they will not be able to make changes to your application.</i></p>`
            }
        ]
    }
}